<template>
  <div>
    <div
      class="container"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/home/Other002373-img2a.jpg') + ')',
      }"
    >
      <el-row>
        <el-col class="col-480" :sm="24" :md="6">
          <div class="product-box product-box-last product-title">
            <!-- 标题，中文有 -->
            <h2></h2>
            <div><p>PRODUCT CENTER</p></div>
            <div
              class="head-more bgcenter"
              @click="toMore('/product/index?id=1')"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/home/head_more_bg.png') + ')',
              }"
            >
              Read more
            </div>
          </div>
        </el-col>
        <el-col class="col-480" :xs="24" :sm="24" :md="6">
          <div
            class="bg-cover product-box product-box-title"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/img/home/20190807114547020.jpg') +
                ')',
            }"
          >
            <div class="cartain" @click="goDetail('home1')">
              <div class="p yichu">85 Inches Glasses-fr...</div>
              <div class="more" @click="goDetail('home1')">Read more</div>
            </div>
          </div>
        </el-col>
        <el-col class="col-480" :xs="12" :sm="12" :md="6">
          <div class="product-box product-doubol">
            <div
              class="bg-cover"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/img/home/Floor002367-img2.jpg') +
                  ')',
              }"
            >
              <div class="cartain">
                <div class="p yichu" @click="goDetail('home2')">
                  42 Inches Glasses-fr...
                </div>
                <div class="more" @click="goDetail('home2')">Read more</div>
              </div>
            </div>
            <div
              class="bg-cover"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/img/home/Floor002367-img4.jpg') +
                  ')',
              }"
            >
              <div class="cartain">
                <div class="p yichu" @click="goDetail('home3')">
                  32 inches Glasses-free 3D Smart Display
                </div>
                <div class="more" @click="goDetail('home3')">Read more</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="col-480" :xs="12" :sm="12" :md="6">
          <div class="product-box product-doubol">
            <div
              class="bg-cover"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/img/home/Floor002367-img3.jpg') +
                  ')',
              }"
            >
              <div class="cartain">
                <div class="p yichu" @click="goDetail('home4')">
                  55 inches Glasses-free 3D Smart Display
                </div>
                <div class="more" @click="goDetail('home4')">Read more</div>
              </div>
            </div>
            <div
              class="bg-cover"
              :style="{
                backgroundImage:
                  'url(' +
                  require('@/assets/img/home/Floor002367-img5.jpg') +
                  ')',
              }"
            >
              <div class="cartain">
                <div class="p yichu" @click="goDetail('home5')">
                  98" Glasses-Free 3D Display
                </div>
                <div class="more" @click="goDetail('home5')">Read more</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  data() {
    return {
      cartainShow: false,
    };
  },
  methods: {
    goDetail(index) {
      this.$router.push("/product/productDetail?itemId=" + index);
    },
    toMore(index) {
      this.$router.push({ path: "/product/index", query: { id: 1 } });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 480px;
  box-sizing: border-box;
  // background-size:100% 100% ;
  padding: 5% 3% 3% 0;
  .product-title {
    color: #fff;
    font-weight: lighter;
    letter-spacing: 2px;
  }
  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 34px;
    color: #fff;
    font-weight: lighter;
    letter-spacing: 2px;
  }
  p {
    margin: 0;
    padding: 10% 0 20%;
    font-size: 48px;
    line-height: 0.9;
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
  }
  .head-more {
    width: 160px;
    // height: 50px;
    color: #fff;
    line-height: 48px;
    display: block;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
      box-sizing: border-box;
      color: #0086d1;
      background: none !important;
      outline: 1px solid #0086d1;
    }
  }
  .bg-cover {
    position: relative;
    &:hover .cartain {
      // display 导致displayflex失效 bug
      // display: block;
      opacity: 1;
    }
  }
  .cartain {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    // display: none;
    opacity: 0;
    .p {
      width: 100%;
      font-size: 24px;
      // padding:0 24px;
      box-sizing: border-box;
      font-weight: normal;
      padding-bottom: 7%;
      cursor: pointer;
    }
    .more {
      height: 40px;
      line-height: 40px;
      width: 130px;
      display: inline-block;
      background: rgba(0, 134, 209, 0.7);
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .product-box {
    height: 410px;
    margin-left: 15px;
    &.product-box-last {
      margin-left: 0px;
    }
    &.product-doubol {
      > div:first-child {
        margin-bottom: 15px;
      }
    }
    .bg-cover {
      height: calc(50% - 7.5px);
    }
  }
  .diplayf {
    display: flex;
  }
  .bgcenter {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0;
    .product-box {
      margin-left: 0;
      padding: 0 10px;
    }
    p {
      padding: 15px 0;
      font-size: 28px;
    }
    .bgcenter {
      display: none;
    }
    .product-box.product-box-last {
      height: 120px;
      margin-left: auto;
    }
    .product-box-title {
      width: 386px;
      margin: 0 auto;
    }
    .product-doubol {
      margin-top: 20px;
    }
  }
}
@media (max-width: 386px) {
  .container {
    .product-box-title {
      width: 100%;
      margin: 0 auto;
      padding: 0;
    }
  }
}
</style>>

