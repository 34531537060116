import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'zxcasdqwe123',
    PROD_URL:'http://10.10.20.72:8088/',
    TEST_URL:'http://localhost:8081/'
  },
  mutations: {
    setState(state,val){
      state.token = val
      console.log('token的值被修改：'+val);
    }
  },
  actions: {
  },
  modules: {
  }
})
