<template>
  <div
    class="app-container"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/home/Other002373-img2a.jpg') + ')',
    }"
  >
    <div class="videoTitle">
      <!-- <h2>视频中心</h2> -->
      <p>VIDEO</p>
    </div>
    <div class="swiper swiper-container">
      <swiper ref="mySwiper swiper-wrapper" :options="swiperOptions2">
        <!-- <swiper-slide
                v-for="item in 3"
                :key="item"
                :style="{backgroundImage: 'url('+require('@/assets/img/20190820134938391.jpg')+')'}">
                </swiper-slide> -->
        <div class="swiper-slide videoFater">
          <div
            class="vedioBox"
            v-show="vedioBoxShow"
            :class="{ videoShow: videoShow == 1 }"
          >
            <div class="playBox" @click="palyClick(1)">
              <i class="el-icon-video-play"></i>
              <p>Click to play</p>
            </div>
            <img src="@/assets/img/20190820135023828.jpg" alt="" />
          </div>
          <video
            id="myVideo1"
            width="100%"
            height="100%"
            aotoplay
            controls
            loop
          >
            <source src="@/assets/video/20190918174606810.mp4" controls type="video/mp4" />
            Your browser does not support HTML5 Video Tags.
          </video>
        </div>
        <div class="swiper-slide videoFater">
          <div
            class="vedioBox"
            v-show="vedioBoxShow"
            :class="{ videoShow: videoShow == 2 }"
          >
            <div class="playBox" @click="palyClick('2')">
              <i class="el-icon-video-play"></i>
              <p>Click to play</p>
            </div>
            <img src="@/assets/img/Floor002658_01.jpg" alt="" />
          </div>
          <video
            id="myVideo2"
            width="100%"
            height="100%"
            aotoplay
            controls
            loop
          >
            <source src="@/assets/video/20191009144140753.mp4" controls type="video/mp4" />
            Your browser does not support HTML5 Video Tags.
          </video>
        </div>
        <div class="swiper-slide videoFater">
          <div
            class="vedioBox"
            v-show="vedioBoxShow"
            :class="{ videoShow: videoShow == 3 }"
          >
            <div class="playBox" @click="palyClick('3')">
              <i class="el-icon-video-play"></i>
              <p>Click to play</p>
            </div>
            <img src="@/assets/img/20190820135023828.jpg" alt="" />
          </div>
          <video
            id="myVideo3"
            width="100%"
            height="100%"
            aotoplay
            controls
            loop
          >
            <source src="@/assets/video/test.mp4" controls type="video/mp4" />
            <!-- 您的浏览器不支持 HTML5 video 标签。 -->
            Your browser does not support HTML5 Video Tags.
          </video>
        </div>
      </swiper>
      <div class="swiper-button-prev"></div>
      <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
      <div class="swiper-button-next"></div>
      <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      <!-- <div class="skitter-large-box">
                <div class="skitter skitter-large with-thumbs" ref="skitter-large">
                    <ul>
                        <li>
                         <router-link to="#swapBlocks">
                            <img src="https://visualhunt.com/photos/l/1/aurora-borealis-over-mountains-at-night.jpg" class="swapBlocks" />
                        </router-link>
                           
                        </li>
                                                <li>
                            
                                <img src="'../../assets/img/20190820134938391.jpg'" alt="">
                          
                        </li>
                    </ul>
                </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper/dist/vue-awesome-swiper'

import 'swiper/dist/css/swiper.min.css'
export default {
  name: "homeVideo",
  components:{
swiper, swiperSlide
  },
  data() {
    return {
      myVideo: "",
      videoPlay: "", //video播放，只允许播放一个
      vedioBoxShow: "0",
      videoShow: 0,

      swiperOptions2: {
        //swiper控制器
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // direction : 'vertical',//竖向
        mousewheel: false, //鼠标活动
        // loop:true,//轮询
        // initialSlide:1,//设定初始化时slide的索引
        slidesPerView: "auto",
        //         autoplay: {
        //   //自动播放
        //   delay: 2000,

        //   stopOnLastSlide: false,

        //   disableOnInteraction: false,
        // },
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 30,
        on: {
          slideChangeTransitionEnd: function () {
            //轮播图切换时
            //恢复遮掩层
            this.videoShow = 0;
            //如果有视频在播放，暂停播放
            this.myVideo ? this.myVideo.pause() : "";
            //bind 将this指向data，获取遮掩层和正在播放的视频
          }.bind(this),
        },
      },
    };
  },
  methods: {
    palyClick(number) {
      this.myVideo = document.getElementById(`myVideo${number}`);
      this.videoPlay = !this.videoPlay;
      this.videoShow = number;
      //先暂停播放其他
      this.myVideo.pause();
      this.myVideo.play();
      //pause 暂停
    },
    // swiperNext() {
    //   this.$refs.mySwiper.slideNext();
    // },
    // swiperPrev() {
    //   this.$refs.mySwiper.slidePrev();
    // },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  .swiper-container {
    width: 100%;
    height: 460px;
    // margin: 20px auto;
    padding-bottom: 30px;
  }
  .videoTitle {
    font-size: 16px;
    padding: 30px 0 0 0;
    h2 {
      margin: 0;
      font-weight: normal;
      font-size: 34px;
      color: #fff;
      font-weight: lighter;
      letter-spacing: 2px;
    }
    p {
      margin: 0;
      padding: 20px 0 20px;
      font-size: 48px;
      line-height: 0.9;
      color: rgba(255, 255, 255, 0.3);
      text-transform: uppercase;
    }
  }
  .videoShow {
    visibility: hidden;
  }
  .videoFater {
    position: relative;
    video {
      background: black;
    }
    .vedioBox,
    .playBox {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        color: #0086d1;
      }
    }

    .playBox {
      text-align: center;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 46px;
      }
      p {
        margin: 10px;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 80%;
    height: 100%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  // .swiper-slide:nth-child(2n) {
  //     width: 80%;
  // }
  // .swiper-slide:nth-child(3n) {
  //     width: 80%;
  // }
}
@media (max-width: 992px) {
  .app-container {
    .videoTitle {
      p {
        padding: 15px 0;
        font-size: 28px;
      }
    }
  }
}
@media (max-width: 426px) {
  .app-container{
    .swiper-container{
      height: auto;
    }
  }
  .swiper-button-next,.swiper-button-prev{
    display: none;
  }
}
</style>>

