import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/index.css'
import './assets/css/initStyle.css'
import './assets/css/font/iconfont.css'

import { Button, Select, Message, MenuItem ,Menu, Submenu, Icon,Row, Col, MenuItemGroup,Pagination,Backtop ,Avatar,Badge, Form,
  FormItem,Input,Dialog,Radio,RadioGroup,Checkbox,CheckboxGroup,Switch,TimePicker,DatePicker,Option,MessageBox,Collapse,CollapseItem} from 'element-ui';
Vue.use(Select).use(Button).use(MenuItem).use(Menu).use(Submenu).use(Icon).use(Row).use(Col)
.use(MenuItemGroup).use(Pagination).use(Backtop).use(Avatar).use(Badge).use(Form).use(FormItem)
.use(Input).use(Dialog).use(Radio).use(RadioGroup).use(Checkbox).use(CheckboxGroup).use(Switch)
.use(TimePicker).use(DatePicker).use(Option).use(Collapse).use(CollapseItem)

//全局提示框
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.config.productionTip = false


import i18n from './i18n/i18n' //谨慎升级i18n！！！！ 升级会导致不能用，如被动升级，可回退 @8.26.5
new Vue({
  router,
  store,i18n,
  render: h => h(App) 
}).$mount('#app')