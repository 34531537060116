var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container",style:({
      backgroundImage:
        'url(' + require('@/assets/img/home/Other002373-img2a.jpg') + ')',
    })},[_c('el-row',[_c('el-col',{staticClass:"col-480",attrs:{"sm":24,"md":6}},[_c('div',{staticClass:"product-box product-box-last product-title"},[_c('h2'),_c('div',[_c('p',[_vm._v("PRODUCT CENTER")])]),_c('div',{staticClass:"head-more bgcenter",style:({
              backgroundImage:
                'url(' + require('@/assets/img/home/head_more_bg.png') + ')',
            }),on:{"click":function($event){return _vm.toMore('/product/index?id=1')}}},[_vm._v(" Read more ")])])]),_c('el-col',{staticClass:"col-480",attrs:{"xs":24,"sm":24,"md":6}},[_c('div',{staticClass:"bg-cover product-box product-box-title",style:({
            backgroundImage:
              'url(' +
              require('@/assets/img/home/20190807114547020.jpg') +
              ')',
          })},[_c('div',{staticClass:"cartain",on:{"click":function($event){return _vm.goDetail('home1')}}},[_c('div',{staticClass:"p yichu"},[_vm._v("85 Inches Glasses-fr...")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.goDetail('home1')}}},[_vm._v("Read more")])])])]),_c('el-col',{staticClass:"col-480",attrs:{"xs":12,"sm":12,"md":6}},[_c('div',{staticClass:"product-box product-doubol"},[_c('div',{staticClass:"bg-cover",style:({
              backgroundImage:
                'url(' +
                require('@/assets/img/home/Floor002367-img2.jpg') +
                ')',
            })},[_c('div',{staticClass:"cartain"},[_c('div',{staticClass:"p yichu",on:{"click":function($event){return _vm.goDetail('home2')}}},[_vm._v(" 42 Inches Glasses-fr... ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.goDetail('home2')}}},[_vm._v("Read more")])])]),_c('div',{staticClass:"bg-cover",style:({
              backgroundImage:
                'url(' +
                require('@/assets/img/home/Floor002367-img4.jpg') +
                ')',
            })},[_c('div',{staticClass:"cartain"},[_c('div',{staticClass:"p yichu",on:{"click":function($event){return _vm.goDetail('home3')}}},[_vm._v(" 32 inches Glasses-free 3D Smart Display ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.goDetail('home3')}}},[_vm._v("Read more")])])])])]),_c('el-col',{staticClass:"col-480",attrs:{"xs":12,"sm":12,"md":6}},[_c('div',{staticClass:"product-box product-doubol"},[_c('div',{staticClass:"bg-cover",style:({
              backgroundImage:
                'url(' +
                require('@/assets/img/home/Floor002367-img3.jpg') +
                ')',
            })},[_c('div',{staticClass:"cartain"},[_c('div',{staticClass:"p yichu",on:{"click":function($event){return _vm.goDetail('home4')}}},[_vm._v(" 55 inches Glasses-free 3D Smart Display ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.goDetail('home4')}}},[_vm._v("Read more")])])]),_c('div',{staticClass:"bg-cover",style:({
              backgroundImage:
                'url(' +
                require('@/assets/img/home/Floor002367-img5.jpg') +
                ')',
            })},[_c('div',{staticClass:"cartain"},[_c('div',{staticClass:"p yichu",on:{"click":function($event){return _vm.goDetail('home5')}}},[_vm._v(" 98\" Glasses-Free 3D Display ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.goDetail('home5')}}},[_vm._v("Read more")])])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }