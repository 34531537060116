module.exports = {
    concat:{
        mailbox:'Mailbox',
        FactoryAddress:'Factory address',
        FactoryAddressDetail:'4F, Block 1, Hualite Industrial Park, Tongguan Road, Guangming District, Shenzhen',
        OfficeAddress:'Office address',
        OfficeAddressDetail:'8F, Block A7, Nanshan Park, 1001 Xueyuan Ave, Taoyuan Town, Nanshan Dist, Shenzhen',
        name:'Name',
        number:'Contact information',
        EMail:'E-mail',
        massage:'Message content',
        followUs:'Follow us'
    },
    navMenu:{
        home:'Home',
        about:'About',
        CompanyProfile:'Company Profile',
        honor:'Honor',
        product:'Product',
        display:'Glasses-Free 3D Display',
        wall:'3D Video Wall',
        screenProtectors:'Glasses-Free 3D screen protectors',
        RealityPlayer:'Reality Player 2.0',
        references:'References',
        apply:'apply',
        LCD3D:'Glasses-Free 3D LCD',
        wall3D:'3D Video Wall',
        news:'news',
        companyNews:'Company news',
        industryNews:'Industry news',
        concat:'Contact',
        ContactInformation:'Contact information'

    },
    product:{
        
        hardware:'Hardware',
        mobile:'Mobile',
        HolographicCamera:'Holographic camera',
        LCD3D:'Glasses-Free 3D LCD',
        PolarizedLight:'Polarized light',
        software:'Software',
        unityPlay:'Unity 3DPlayIn',
        DD:'2D to 3D',
        DPlay:'3D player',
        Dmake:'3D production software',
        Visyal3D:'3D Visual',
        VisualHealthCloud:'Visual Health Cloud'
    },
    error:{
        noDome:'Sorry, the page you visited does not exist!',
        causedBy:'This error may be caused by:',
        urlErr:'URL input error',
        urlFaild:'The url has failed',
        eg:'It is recommended that you continue to visit this website through the following links:',
        other:'Other reasons'
    }
}