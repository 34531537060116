<template>
  <div class="header001">
    <div class="header002 header">
      <div class="ycenterT">
        <div class="header-left">
          <router-link to="/">
            <img
              src="@/assets/img/Floor002650_logo.png"
              slot=""
              alt="维超智能"
            />
          </router-link>
        </div>
        <div class="header-right spaceDisplay">
          <el-menu
            id="menu001"
            router
            collapse-transition
            :default-active="activeIndex"
            class="el-menu-demo"
            :mode="menuMode"
            @select="handleSelect"
            active-text-color="#0068b7"
          >
            <template v-for="menuList in menuData">
              <el-menu-item
                v-if="!menuList.childMenu"
                :index="menuList.index"
                v-html="menuList.title"
                @click="Ctitle"
                :key="menuList.index"
              ></el-menu-item>
              <el-submenu v-else :index="menuList.index" :key="menuList.title">
                <template slot="title" @click="Ctitle">
                  {{ menuList.title }}
                </template>
                <template v-for="menuList2 in menuList.childMenu">
                  <el-menu-item
                    v-if="!menuList2.childMenu"
                    :key="menuList2.index"
                    :index="menuList2.index"
                    v-html="menuList2.title"
                  >
                  </el-menu-item>
                  <el-submenu
                    v-else
                    :index="menuList2.index"
                    :key="menuList2.title"
                  >
                    <template slot="title" @click="Ctitle">{{
                      menuList2.title
                    }}</template>
                    <el-menu-item
                      v-for="item2 in menuList2.childMenu"
                      :key="item2.title"
                      :index="item2.index"
                      v-html="item2.title"
                    >
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-submenu>
            </template>
          </el-menu>

          <div class="lang">
            <a href="http://www.magicee.cn/" class="aNoB">中</a>
            <a href="http://en.magicee.cn/">EN</a>
          </div>
          <div class="search-right">
            <i class="el-icon-search"></i>
          </div>
          <!-- <div class="search-right mediaMenu" @click="openMenu">
            <i class="el-icon-search"></i>
          </div> -->
          <!-- 汉堡按钮 -->
          <div class="three col">
            <div
              class="hamburger"
              id="hamburger-5"
              :class="{ 'is-active': this.activc }"
              @click="clickActive"
            >
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-row class="tac menuMIn" v-if="minShow">
      <el-col
        :span="12"
        class="menuMInSubmenu"
        :class="{ menuMInSubmenuActive: this.activc }"
        trigger="manual"
      >
        <!-- <el-menu
          default-active="activeIndex"
          ref="projectItem"
          router
          collapse-transition
          unique-opened
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
        >
          <div v-for="menuList in menuData" :key="menuList.index">
            <el-menu-item v-if="!menuList.childMenu" :index="menuList.index">
              <span slot="title" v-html="menuList.title">首页</span>
            </el-menu-item>
            <el-submenu
              class="el-menu-vertical-demo"
              v-else
              :index="menuList.index"
            >
              <template slot="title">
                <span v-html="menuList.title">导航一</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="(menuList2, menuKey) in menuList.childMenu"
                  :key="menuKey"
                  :index="menuList2.index"
                  v-html="menuList2.title"
                  >选项1</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu> -->
        <el-menu
          default-active="activeIndex"
          ref="projectItem"
          router
          collapse-transition
          unique-opened
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
        >
          <template v-for="menuList in menuData">
            <el-menu-item
              v-if="!menuList.childMenu"
              :index="menuList.index"
              :key="menuList.index"
            >
              <span slot="title" v-html="menuList.title">首页</span>
            </el-menu-item>

            <el-submenu
              class="el-menu-vertical-demo"
              v-else
              :key="menuList.index"
              :index="menuList.index"
            >
              <template slot="title">
                <span v-html="menuList.title">导航一</span>
              </template>

              <el-menu-item-group>
                <template v-for="(menuList2, menuKey) in menuList.childMenu">
                  <el-menu-item
                    v-if="!menuList2.childMenu"
                    :key="menuKey"
                    :index="menuList2.index"
                    v-html="menuList2.title"
                    >选项1</el-menu-item
                  >
                  <el-submenu v-else :key="menuKey" :index="menuList2.index">
                    <template slot="title">{{ menuList2.title }}</template>
                    <el-menu-item
                      v-for="menuList3 in menuList2.childMenu"
                      :key="menuList3.index"
                      :index="menuList3.index"
                      v-html="menuList3.title"
                      >选项1</el-menu-item
                    >
                  </el-submenu>
                </template>

                <!-- <el-menu-item index="1-2">选项2</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  name: "index",
  data() {
    return {
      activeIndex: this.$route.fullPath, //导航选中
      mounIndex: 0, //侧边下拉列表 index
      // show: false,
      minShow: false, //侧边列表隐藏与显示
      activc: false, //汉堡按钮状态
      //控制汉堡列表方向
      menuMode: "horizontal",
      animate: "animate__fadeInDown",
      //导航数据
      menuData: [
        { title: this.$t("navMenu.home"), index: "/" },
        {
          title: this.$t("navMenu.about"),
          index: "/about",
          childMenu: [
            { index: "/about?id=1", title: this.$t("navMenu.CompanyProfile") },
            { index: "/about?id=2", title: this.$t("navMenu.honor") },
          ],
        },
        {
          title: this.$t("navMenu.product"),
          index: "/product",
          childMenu: [
            {
              // 硬件
              title: this.$t("product.hardware"),
              index: "/product/index",
              childMenu: [
                {
                  index: "/product/index?id=1",
                  title: this.$t("navMenu.display"),
                  query: { id: 1 },
                },
                {
                  index: "/product/index?id=2",
                  title: this.$t("navMenu.wall"),
                  query: { id: 2 },
                },
                {
                  index: "/product/index?id=3",
                  title: this.$t("navMenu.screenProtectors"),
                  query: { id: 3 },
                },
                {
                  index: "/product/index?id=4",
                  title: this.$t("navMenu.RealityPlayer"),
                  query: { id: 4 },
                },
              ],
            },
            {
              //软件
              title: this.$t("product.software"),
              index: "/product/software",
              childMenu: [
                {
                  index: "/product/software?id=1",
                  title: this.$t("product.unityPlay"),
                  query: { id: 1 },
                },
                {
                  index: "/product/software?id=2",
                  title: this.$t("product.DD"),
                  query: { id: 2 },
                },
                {
                  index: "/product/software?id=3",
                  title: this.$t("product.Visyal3D"),
                  query: { id: 3 },
                },
                {
                  index: "/product/software?id=4",
                  title: this.$t("product.VisualHealthCloud"),
                  query: { id: 4 },
                },
              ],
            },
          ],
        },
        {
          title: this.$t("navMenu.references"),
          index: "/apply",
          childMenu: [
            {
              index: "/apply/index?id=1",
              title: this.$t("navMenu.references"),
            },
            { index: "/apply/index?id=2", title: this.$t("navMenu.wall3D") },
          ],
        },
        {
          title: this.$t("navMenu.news"),
          index: "/news",
          childMenu: [
            {
              index: "/news/index?id=1",
              title: this.$t("navMenu.companyNews"),
            },
            {
              index: "/news/index?id=2",
              title: this.$t("navMenu.industryNews"),
            },
          ],
        },
        {
          title: this.$t("navMenu.concat"),
          index: "/concat",
          childMenu: [
            { index: "/concat", title: this.$t("navMenu.ContactInformation") },
          ],
        },
        // {
        //   title: "论坛",
        //   index: "/BBS",
        //   childMenu: [{ index: "/BBS", title: "论坛" }],
        // },
      ],
      swiperOptions: {
        //swiper控制器
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
          bulletClass: "info_slide",
          bulletActiveClass: "info_slideActive",
          // paginationBulletRender(swiper,index, className){
          //     return  '<div class="'+className+'">'+ (index + 1) + '</div>';
          //     // 这里className表示默认的swiper分页器类名
          // }
        },
        // direction : 'vertical',//竖向
        mousewheel: false, //鼠标活动
        loop: true, //轮询
        initialSlide: 1, //设定初始化时slide的索引
      },
    };
  },
  mounted() {},
  methods: {
    sharkAnimate() {
      // this.animate = 'animate__jello'
      document.querySelector(".animateDiv").className =
        "animateDiv animate__animated";
      setTimeout(() => {
        document.querySelector(".animateDiv").className =
          "animateDiv animate__animated animate__jello";
      }, 0);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.clickActive();
    },
    // enter() {
    //   console.log("11");
    // },
    Ctitle() {
      console.log("123");
    },
    //导航栏
    clickActive() {
      this.minShow = !this.minShow;
      this.activc = !this.activc;
      if (this.minShow) this.$emit("changeActive");
      //    document.addEventListener('click',(e)=>{
      //     console.log(e.target.className);
      //     if(!this.$refs.projectItem.contains(e.target)){
      //         this.minShow = false;
      //     }
      // })
    },

    handleOpen(key, keyPath) {
      this.mounIndex = key;
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {},
  watch: {
    $route: {
      handler(val, oldval) {
        //根据路由信息确定当前头部active，解决刷新后active重置问题
        this.activeIndex = val.fullPath;
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.animateDiv {
  width: 18.75rem;
  height: 18.75rem;
  background-color: bisque;
  margin: 0 auto;
}
.ycenterT {
  display: flex;
  justify-content: space-between;
}
.spaceDisplay {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.header001 {
  height: 100%;
  .header002 {
    padding: 1% 5% 0%;
    box-sizing: border-box;
    width: 100%;
    z-index: 9;
    background: #fff;
  }
  .header-left {
    width: 30%;
    display: flex;
    align-items: left;
    > a {
      img {
        width: 100%;
      }
    }

    .el-menu-demo {
      font-size: 16px;
      position: relative;
      padding-bottom: 26px;
      display: inline-block;
      color: #333333;
      font-weight: 400;
      line-height: 36px;
    }
  }
  .header-right {
    padding: 7px;
    position: relative;
  }
  .top-meun {
    max-width: 16.666%;
    text-align: center;
  }
  .lang {
    min-width: 74px;
    outline: 1px solid #0068b7;
    line-height: 34px;
    overflow: hidden;
    display: flex;
    margin-bottom: 20px;
    a {
      background: #0068b7;
      color: #fff;
      width: 50%;
      text-align: center;
    }
    .aNoB {
      background: white;
      color: #0068b7;
    }
  }
  .search-right {
    font-size: 30px;
    margin-bottom: 20px;
    .el-icon-search {
      text-align: 30px;
      line-height: 30px;
    }
    .el-icon-search:hover {
      color: #0068b7;
    }
    margin-left: 20px;
    padding: 2px 0;
  }

  //swiper css start
  .app-container {
    .swiper {
      width: 100%;
      height: 500px;
      .swiper-container {
        width: 100%;
        height: 500px;
        .swiper-slide {
          width: 100%;
          height: 100%;
          // padding-bottom: 40%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }

        .swiper-pagination {
          .swiper-pagination-bullet-active {
            background-color: #f29b76;
          }
        }
      }
    }
  }

  .el-menu-demo .el-menu--horizontal .el-menu:hover {
    .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
      border-bottom: 2px solid #409eff;
      color: #303133;
    }
  }
  @media (max-width: 767px) {
  }
  @media (min-width: 980px) {
    //大于980时，汉堡菜单隐藏
    .menuMIn {
      display: none;
    }
  }
  @media (max-width: 980px) {
    #menu001 {
      display: none;
    }
    .header-left {
      align-items: center;
    }
    .header-right {
      padding: 0;
    }
    .three,
    .spaceDisplay {
      display: flex;
      justify-content: flex-end;
    }
  }
  .el-menu--horizontal {
    .el-menu-item {
      font-size: 16px;
    }
    .el-menu {
      .el-menu-item {
        //下拉菜单最大宽度
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style lang="less">
.header001 {
  #menu001 {
    margin-right: 10px;
  }
  .el-menu {
    border-right: none;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    display: none;
  }
  .el-menu-item {
    padding: 0 10px;
  }
  .el-submenu__title {
    padding: 0 10px;
  }
  .el-menu--horizontal > .el-menu-item,
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    line-height: 40px;
  }
  // .el-menu--horizontal>.el-menu-item.is-active{
  //     border: 2px solid red;
  // }

  .info_slide {
    position: relative;
    display: inline-block;
    // width: 116px;
    height: 41px;
    overflow: hidden;
    line-height: 1000px;
    background: url("~@/assets/img/Floor002650_dot.png") no-repeat left center;
    padding: 0 70px 0 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .info_slideActive {
    position: relative;
    display: inline-block;
    // width: 116px;
    height: 41px;
    overflow: hidden;
    line-height: 1000px;
    background: url("~@/assets/img/Floor002650_dot_on.png") no-repeat left
      center;
    padding: 0 70px 0 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .el-menu-demo {
    .el-submenu__title {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .menuMIn {
    //响应式导航栏
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    transition: all 0.2s;
    text-align: left;
    transition: all 0.3s;
    // right: 20px;

    .menuMInSubmenu {
      position: absolute;
      top: -100px;
      opacity: 0;
      transition: all 0.2s;
    }
    .el-menu-vertical-demo {
      .el-menu-item-group,
      .el-menu-item {
        background: #0068b7;
        ul {
          li {
            .el-submenu__title {
              color: #fff;
              &:hover {
                background-color: #0068b7;
              }
            }
          }
        }
      }
      .el-menu-item {
        border-bottom: 1px solid #8fa8ba;
        color: #fff;
      }
    }
    .el-menu-item,
    .el-submenu__titl {
      height: auto;
      line-height: 36px;
      min-width: 50px;
      word-wrap: break-word;
      // word-break:break-all;
      white-space: pre-wrap;

      // word-break: ;
    }
    .menuMInSubmenuActive {
      transition: all 0.2s;
      top: 0px;
      opacity: 1;
      > ul {
        > li {
          > ul {
            background: #eee;
          }
          border-bottom: 1px solid #eee;
          .el-submenu__title {
            > i {
              &::before {
                color: #02101b;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  .el-menu--horizontal .el-menu .el-menu-item,
  .el-menu--horizontal .el-menu .el-submenu__title {
    padding-right: 20px;
  }
}
</style>>

<style scoped>
.three {
  transform: scale(0.8);
  margin-bottom: 16px;
  margin-left: 10px;
  display: none;
}
.row .three {
  padding: 80px 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line {
  width: 50px;
  height: 5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}
#hamburger-5.is-active {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#hamburger-5.is-active .line:nth-child(2) {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

#hamburger-5 .line:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

#hamburger-5.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-5.is-active .line:nth-child(1),
#hamburger-5.is-active .line:nth-child(3) {
  width: 35px;
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
}

#hamburger-5.is-active .line:nth-child(1) {
  -webkit-transform: translateY(15px) rotate(45deg);
  -ms-transform: translateY(15px) rotate(45deg);
  -o-transform: translateY(15px) rotate(45deg);
  transform: translateY(15px) rotate(45deg);
}

#hamburger-5.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-15px) rotate(-45deg);
  -ms-transform: translateY(-15px) rotate(-45deg);
  -o-transform: translateY(-15px) rotate(-45deg);
  transform: translateY(-15px) rotate(-45deg);
}
</style>


    
