<template>
  <div class="home">
    <!-- <Header></Header> -->
    <!-- <h1>{{$t("message.title")}}</h1> -->
    <bodySwiper></bodySwiper>
    <product></product>
    <new-product></new-product>
    <Video></Video>
    <!-- <Footer></Footer> -->
    <!-- <Home></Home> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header/index.vue'
import bodySwiper from '@/components/home/bodySwiper/bodySwiper.vue'
import product from '@/components/home/product/product.vue'
import NewProduct from '@/components/home/product/newProduct.vue'
import Video from '@/components/home/video/video.vue'
import Footer from '@/components/footer/footer.vue'
export default {
  name: 'index',
  components: {
    //组件懒加载，首页白屏问题
    "bodySwiper":resolve=>require(['@/components/home/bodySwiper/bodySwiper.vue'],resolve),
    "Header":resolve=>require(['@/components/header/index.vue'],resolve),
    "product":resolve=>require(['@/components/home/product/product.vue'],resolve),
    "NewProduct":resolve=>require(['@/components/home/product/newProduct.vue'],resolve),
    "Video":resolve=>require(['@/components/home/video/video.vue'],resolve),
    "Footer":resolve=>require(['@/components/footer/footer.vue'],resolve),
  }
}
</script>
