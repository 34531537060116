<template>
  <div>
    <div v-if="activeShow" class="goTop" ref="goTop" @click="goTopClick">
      <i class="el-icon-caret-top"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        //默认关闭
      activeShow: false,
    };
  },
  methods: {
    goTopClick() {
      document.documentElement.scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {
      let t = document.documentElement.scrollTop || document.body.scrollTop;
      if (t > 1200) {
        this.activeShow = true;
      } else {
        this.activeShow = false;
      }
    },
  },
  mounted() {
      //监听滚动条
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="less" scoped>
.goTop {
  position: fixed;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 5;
  right: 5%;
  bottom: 10%;
  &:hover {
    background-color: #f2f6fc;
  }
}
</style>