import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children:[
      {
        path: '/',
        name: 'home',
        component: Home,
      },
  {

    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import( '../views/Product.vue'),
    children: [
      {
        path: '/product/productDetail',
        component: () => import( '../components/product/productDetail.vue')
      },
      {
        path: '/product/index',
        component: () => import('../components/product/product.vue')
      },
      {
        path: '/product/software',
        component: () => import('../components/product/software.vue')
      }
    ]
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import('../views/Apply.vue'),
    children: [
      {
        path: '/apply/index',
        component: () => import('../components/apply/apply.vue')
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    children: [
      {
        path: '/news/index',
        component: () => import('../components/news/news.vue')
      },
      {
        path: '/news/detail',
        component: () => import('../components/news/newsDetail.vue')
      },

    ]
  },
  {
    path: '/concat',
    name: 'Concat',
    component: () => import('../views/Concat.vue')

  },
  {
    path: '/BBS',
    name: 'BBS',
    component: () => import(/* webpackChunkName: "about" */ '../views/BBS.vue'),
    children: [
      {
        path: '/BBS',
        name:'BBSDetail',
        component: () => import('../components/BBS/BBSContainer.vue'),
      },
      {
        path: '/BBS/detail',
        name:'BBSDetail',
        component: () => import('../components/BBS/BBSDetail.vue'),
      },

    ]

  },   
  {
    path:'/editor',
    name:'editor',
    component:() => import('../components/editor/editor.vue'),
  }  
    ]
  },
 
  {
    path:'/404',
    name:'404',
    component:()=>import('../components/error/404.vue')
  }

]
let menu =[
  {
    path: '/product',
    name: 'Product',
    filePath: '../views/Product.vue',
    children: [
      {
        path: '/product/productDetail',
        filePath:'../components/product/productDetail.vue'
      },
      {
        path: '/product/index',
        filePath:'../components/product/product.vue'
      },
      {
        path: '/product/software',
        filePath:'../components/product/software.vue'
      }
    ]
  },
]

import {createRoute} from '../assets/js/util'
const router__ = []
if (menu) {
  menu.forEach(function (v) {
    let a = createRoute(v);
    router__.push(createRoute(v));
    if (v.children) {
      v.children.forEach(function (v2) {
        router__.push(createRoute(v2));
        if (v2.children) {
          v2.children.forEach(function (v3) {
            rorouter__utes.push(createRoute(v3));
          });
        }
      });
    }
  });
}
console.log(router__);
const router = new VueRouter({
  // mode: 'history',
  base: "/dist/",
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0,y: 0 }
  // }
})

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.matched.length ===0) {  //如果未匹配到路由
    console.log('如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由');
    this.$massage({
      typeof:'',
    })
    from.name ? next({ name:from.name }) : next('/404');   //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  } else {
    next();    //如果匹配到正确跳转
  }
});
export default router