/**
 * 创建路由对象
 * @returns {boolean}
 * @param data
 */
export const createRoute = (data) => {
  let path = '/' + data.path;
//   if (data.params) {
//     path += '/' + data.params;
//   }
  let filePath = data.filePath;
//   if (data.file_path) {
//     filePath = data.file_path;
//   }
  return {
    name: data.path,
    path: path,
    component: resolve => require(['@/views/' + filePath], resolve),
    // meta: { model: data.pid, info: data },
  };
};