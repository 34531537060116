import { render, staticRenderFns } from "./bodySwiper.vue?vue&type=template&id=42651789&scoped=true&"
import script from "./bodySwiper.vue?vue&type=script&lang=js&"
export * from "./bodySwiper.vue?vue&type=script&lang=js&"
import style0 from "./bodySwiper.vue?vue&type=style&index=0&id=42651789&lang=less&scoped=true&"
import style1 from "./bodySwiper.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42651789",
  null
  
)

export default component.exports