// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {

    concat:{
        mailbox:'邮箱',
        FactoryAddress:'工厂地址',
        FactoryAddressDetail:' 深圳市光明高新技术产业园西区同观路转二号路华力特科技园生产楼四层 ',
        OfficeAddress:'办公地址',
        OfficeAddressDetail:' 深圳市南山区桃源街道长源社区学苑大道1001号南山智园A7栋801 0755-86719891 ',
        name:'姓名',
        number:'联系方式',
        EMail:'电子邮箱',
        massage:'留言内容',
        followUs:'联系我们'
    },
    navMenu:{
        home:'首页',
        about:'关于我们',
        CompanyProfile:'公司介绍',
        honor:'荣誉资质',
        product:'产品中心',
        display:'裸眼3D显示屏',
        wall:'裸眼3D拼接屏',
        screenProtectors:'裸眼3D智能膜',
        RealityPlayer:'智能软件',
        references:'产品应用',
        apply:'apply',
        LCD3D:'裸眼3D显示屏',
        wall3D:'裸眼3D拼接屏',
        news:'新闻中心',
        companyNews:'公司新闻',
        industryNews:'行业新闻',
        concat:'联系我们',
        ContactInformation:'联系方式'
    },
    product:{
       
        hardware:'硬件',
        mobile:'移动端',
        HolographicCamera:'全系相机',
        LCD3D:'裸眼3D显示屏',
        PolarizedLight:'偏光',
         software:'软件',
         unityPlay:'Unity 3DPlayIn',
        DD:'2D转3D',
        DPlay:'3D player',
        Dmake:'3D制作软件',
        Visyal3D:'视觉3D',
        VisualHealthCloud:'视觉健康云'

    },
    error:{
        noDome:'对不起, 你访问的页面不存在!',
        causedBy:'可能是如下原因引起了这个错误:',
        urlErr:'URL输入错误',
        urlFaild:'链接已失效',
        eg:'推荐您通过以下链接继续访问本网站:',
        other:'其他原因...'
        
    }

}