<template>
  <div>
    <div
      class="container"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/home/Other002373-img2a.jpg') + ')',
      }"
    >
      <el-row>
        <el-col :span="5" class="show640 col-640">
          <div class="product-box product-box-last product-title">
            <h2>产品中心</h2>
            <div><p>NEWS CENTER</p></div>
            <div
            @click="toMore('/news/index?id=1')"
              class="head-more bgcenter"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/home/head_more_bg.png') + ')',
              }"
            >
              Read More
            </div>
          </div>
        </el-col>
        <el-col :span="10" class="col-640">
          <div class="bg-cover product-box bgcenter"  @click="toMore('/news/detail?id=1&newsId=11')">
            <img
              class="imgtran"
              src="@/assets/img/news/5g.jpeg"
              alt=""
            />
            <div class="cartain">
              <div class="p yichu1"><span><span>27</span> /2020-7</span> Can 5G bring about the rapid development of glasses-free 3D technology?</div>
            </div>
          </div>
        </el-col>

        <el-col :span="9" class="col-640">
          <div class="product-box product-doubol">
            <div class="newsBox">
              <div class="bgcover">
                <img src="@/assets/img/news/20201214172923319.jpg" alt="" />
              </div>
              <div class="newsDetail"  @click="toMore('/news/detail?id=1&newsId=12')">
                <div class="yichu1 div-first-child">
                  Analysis of the pain points of the naked eye 3D market.
                </div>
                <div class="yichu div-nth-child">
                  
	In the current glasses-free 3D industry, glasses-free 3D display terminals are mainly based on external devices, and major enterprises still remain at the level of fighting for their own traditional hardware display terminals, which also leads to the dispersion of 3D data. Due to the non-interoperability of various platforms, the development of the high-quality 3D content industry is largely limited. A unified data platform has not been established, and benign interactive self-built content sharing cannot be achieved. The richness and scale of the content are lacking. There is still a long way to go in the popularity of the consumer market.
                </div>
                <div class="div-last-child">
                  2020-4-27<i class="el-icon-right"></i>
                </div>
              </div>
            </div>
            <div class="newsBox">
              <div class="bgcover">
                <img src="@/assets/img/news/20201026161411678.jpg" alt="" />
              </div>
              <div class="newsDetail"  @click="toMore('/news/detail?id=1&newsId=13')">
                <div class="yichu1 div-first-child">
                  3D technology market development status and future trends.
                </div>
                <div class="yichu div-nth-child">
	              In recent years, global 3D technologies, including 3D printing, 3D cameras, 3D games, 3D displays, and 3D software, have developed rapidly, and the market scale has continued to expand. According to the data of "Global 3D Technology Market Development Status and Future Trends" released by Markets & Markets, the global 3D technology market size was USD 127.84 billion in 2016, and it is expected to reach USD 245.8 billion by 2022, with a compound annual growth rate of 16.17%. In the upstream of the entire 3D industry, glasses-free 3D  terminal products, 3D scanning, 3D modeling and surveying, etc., have good development prospects.
                </div>
                <div class="div-last-child">
                  2022-2-17<i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="5" class="unShow640">
          <div class="product-box product-box-last product-title">
            <!-- <h2>产品中心</h2> -->
            <div><p>NEWS CENTER</p></div>
            <div
            @click="toMore('/news/index?id=1')"
              class="head-more bgcenter"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/home/head_more_bg.png') + ')',
              }"
            >
               Read More
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="droductNewsBox">
        <el-col :span="6" class="droductNewsBoxBG col-640">
          <div class="hoverbos">
            <div class="droductNewsImg imgcover">
              <img src="@/assets/img/news/20191009151351728.jpg" alt="" />
            </div>
            <div class="newsDetailBox">
              <div class="newsDetail"  @click="toMore('/news/detail?id=1&newsId=14')">
                <div class="yichu1 div-first-child">
                 Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020.
                </div>
                <div class="yichu div-nth-child">
                  Every Spring Festival Gala on New Year's Eve is an appetizing "audiovisual dinner" on the audience's dinner table. In recent years, the "tech taste" of this big meal has become stronger and stronger. This year's Spring Festival Gala stage was the first to create a three-layer three-dimensional dance beauty, while using flying screen technology to create a 360-degree surround landscape, so that the audience can have the ultimate experience of "naked eye 3D" in front of the screen.
                </div>
                <div class="div-last-child">
                  2020-4-28<i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="droductNewsBoxBG col-640">
          <div class="hoverbos">
            <div class="droductNewsImg imgcover">
              <img src="@/assets/img/news/movie.jpeg" alt="" />
            </div>
            <div class="newsDetailBox">
              <div class="newsDetail"  @click="toMore('/news/detail?id=2&newsId=15')">
                <div class="yichu1 div-first-child">
                 Why not use glasses-free 3D projection in movie theaters?
                </div>
                <div class="yichu div-nth-child">
                 Some people will find that the glasses-free 3D technology has clearly appeared, but the actual application is very few. Usually, the  glasses-free 3D technology is not seen at all. Even in some large exhibition halls, such as movie theaters, the 3D projection technology is not widely used. . Why not use glasses-free 3D projection in movie theaters?
                </div>
                <div class="div-last-child">
                  2020-4-28<i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div> </el-col
        ><el-col :span="6" class="droductNewsBoxBG col-640">
          <div class="hoverbos">
            <div class="droductNewsImg imgcover">
              <img src="@/assets/img/news/3Dearth.jpeg" alt="" />
            </div>
            <div class="newsDetailBox">
              <div class="newsDetail"  @click="toMore('/news/detail?id=2&newsId=14')">
                <div class="yichu1 div-first-child">
                  China's first naked-eye 3D map-the original map can be seen like this.
                </div>
                <div class="yichu div-nth-child">
                 Harbin Map Publishing House publishes "The Three-Dimensional Vision 3D China-The People's Republic of China 3D Atlas", which uses 3D visual effects in the map. The atlas is the first naked-eye 3D Chinese atlas in China, which is a collection of high-tech innovations in the current field of cartography. A whole new way.
                </div>
                <div class="div-last-child">
                  2020-4-28<i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div> </el-col
        ><el-col :span="6" class="droductNewsBoxBG col-640">
          <div class="hoverbos">
            <div class="droductNewsImg imgcover">
              <img src="@/assets/img/news/car3D.jpeg" alt="" />
            </div>
            <div class="newsDetailBox">
              <div class="newsDetail"  @click="toMore('/news/detail?id=2&newsId=15')">
                <div class="yichu1 div-first-child">
                 Glasses-free3D display by car is here.
                </div>
                <div class="yichu div-nth-child">
                 Recently, Bosch officially launched a new 3D display. This product uses a passive 3D technology display that can generate very realistic 3D effects compared to traditional displays, making it easier and faster for drivers to grab the screen Display information.
                </div>
                <div class="div-last-child">
                 2020-5-17<i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "newProduct",
  data() {
    return {
      cartainShow: false,
      item:[
        {title:' Analysis of the pain points of the naked eye 3D market.',time:'',detail:'',img:''}
      ]
    };
  },
  methods:{
    toMore(index){
      this.$router.push({path:index});
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 310px;
  box-sizing: border-box;
  // background-size:100% 100% ;
  padding: 5% 3% 3% 0;
  .product-title {
    color: #fff;
    font-weight: lighter;
    letter-spacing: 2px;
  }
  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 34px;
    color: #fff;
    font-weight: lighter;
    letter-spacing: 2px;
  }
  p {
    margin: 0;
    padding: 10% 0 20%;
    font-size: 48px;
    line-height: 0.9;
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
  }
  .imgtran {
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  .head-more {
    width: 160px;
    // height: 50px;
    color: #fff;
    line-height: 48px;
    display: block;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    float: right;
    &:hover {
      box-sizing: border-box;
      color: #0086d1;
      background: none !important;
      outline: 1px solid #0086d1;
    }
  }
  .newsDetail {
    cursor: pointer;
    padding: 10px;
    .div-first-child {
      font-size: 16px;
      padding: 8px 0;
      color: #333;
    }
    .div-nth-child {
      color: #999;
      font-size: 12px;
      margin-bottom: 10px;
      line-height: 20px;
      height: 40px;
      display: inline-block;
    }
    .div-last-child {
      color: #999;
      font-size: 12px;
      text-align: left;
      > i {
        font-size: 20px;
        float: right;
      }
    }
  }
  .droductNewsImg:hover,
  .newsBox:hover,
  .droductNewsBoxBG > .hoverbos:hover {
    background: #0068b7 !important;
    color: #999;
    .div-first-child,
    .div-last-child,
    .div-nth-child {
      color: rgb(255, 250, 250);
    }
  }
  .newsBox {
    > div:first-child {
      width: 40%;
      margin-right: 10px;
      height: 100%;
    }
    > div:last-child {
      width: 60%;
    }

    display: flex;
    justify-content: flex-start;
    background: rgb(255, 255, 255);
  }
  .bg-cover {
    position: relative;
  }
  .cartain {
    position: absolute;
    width: 100%;
    height: 20%;
    background: rgba(0, 0, 0, 0.35);
    top: 80%;
    color: #fff;
    display: flex;
    align-items: center;
    .p {
      width: 100%;
      font-size: 16px;
      // padding:0 24px;
      box-sizing: border-box;
      font-weight: normal;

      cursor: pointer;
      >span{
        padding: 0 15px;
        span{
          font-size: 32px;
          font-weight: bold;
        }
      }
    }
  }
  .product-box {
    height: 310px;
    margin-left: 15px;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    img {
      width: 100%;
      height: 100%;
      float: left;
      transform: scale(1);
      &:hover {
        transition: all 0.2s;
        transform: scale(1.1);
      }
    }
    &.product-box-last {
      margin-left: 0px;
    }
    &.product-doubol {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        height: 50%;
      }
      > div:first-child {
        margin-bottom: 20px;
      }
    }
    .bg-cover {
      height: calc(50% - 7.5px);
    }
  }
  .droductNewsBox {
    height: 320px;
    padding: 15px;
    .droductNewsBoxBG {
      > div {
        background: #fff;
      }

      cursor: pointer;
      box-sizing: border-box;
      &:not(:first-child) {
        padding-left: 20px;
      }
    }
    .newsDetailBox {
      height: 100%;
      .newsDetail {
        padding-bottom: 20px;
      }
    }
    .droductNewsImg {
      width: 100%;
      height: 132px;
    }
  }
  .diplayf {
    display: flex;
  }
  .bgcenter {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
@media (max-width: 992px) {
  .container {
    P {
      padding: 15px 0;
      font-size: 28px;
    }
  }
}
  @media (max-width:426px) {
    .container{
        padding: 0 15px;
      .product-box{
        margin-left: 0;
      }
      .droductNewsBox{
        margin-top: 15px;
        padding: 0;
      }
    }
}  
@media (max-width: 640px) {
  .container {
    .head-more {
      display: none;
    }
    .product-box {
      height: auto;
    }
    .droductNewsBox {
      .droductNewsBoxBG {
        margin-bottom: 20px;
        &:not(:first-child) {
          padding-left: 0px;
        }
      }
    }
  }
}
</style>>

