<template>
  <div class="footer">
    <nav class="clearfix">
      <ol>
        <dt>{{$t("concat.followUs")}}</dt>

        <figure>
          <img src="@/assets/img/footer/20190918155322350.jpg" />
        </figure>

        <div class="bdsharebuttonbox">
          <i
            @click="shareFacebook"
            class="iconfont icon-facebookfacebook52"
            target="_blank"
            title="Share to Facebook"
          ></i>
          <i  
             @click="shareTwitter"
            class="iconfont icon-tuitetwitter43"
            target="_blank"
            title="Share to Twitter"
          ></i>
          <i to="/" class="iconfont icon-instagram" title="Share to ins"></i>
        </div>
      </ol>
      <div class="bottom-menu clearfix">
        <dl>
          <dt>{{$t("navMenu.about")}}</dt>
          <dd>
            <router-link to="/about?id=1"
              >{{$t("navMenu.CompanyProfile")}}</router-link
            >
          </dd>
          <dd>
            <router-link to="/about?id=2"
              >{{$t("navMenu.honor")}}</router-link
            >
          </dd>
          <!-- <dd>
            <router-link to="/organizationalstructure/index.aspx"
              >Organization</router-link
            >
          </dd> -->
        </dl>
        <dl>
          <dt>{{$t("navMenu.product")}}</dt>

          <dd>
            <router-link to="/product/index?id=1"
              >{{$t("navMenu.display")}}</router-link
            >
          </dd>
          <dd>
            <router-link to="/product/index?id=2"
              >{{$t("navMenu.wall")}}</router-link
            >
          </dd>
          <dd>
            <router-link to="/product/index?id=3"
              >{{$t("navMenu.screenProtectors")}}</router-link
            >
          </dd>
          <dd>
            <router-link to="/product/index?id=4"
              >{{$t("navMenu.RealityPlayer")}}</router-link
            >
          </dd>
        </dl>
        <dl>
          <dt>{{$t("navMenu.references")}}</dt>

          <dd>
            <router-link to="/apply/index?id=1"
              >{{$t("navMenu.LCD3D")}}</router-link
            >
          </dd>
          <dd>
            <router-link to="/apply/index?id=2"
              >{{$t("navMenu.wall3D")}}</router-link
            >
          </dd>
        </dl>
        <dl>
          <dt>{{$t("navMenu.news")}}</dt>
          <dd>
            <router-link to="/news/index?id=1">{{$t("navMenu.companyNews")}}</router-link>
          </dd>
          <dd>
            <router-link to="/news/index?id=2">{{$t("navMenu.industryNews")}}</router-link>
          </dd>
        </dl>
        <dl>
          <dt>{{$t("navMenu.concat")}}</dt>
          <dd>
            <router-link  to="/concat">{{$t("navMenu.ContactInformation")}}</router-link>
          </dd>
        </dl>
      </div>
    </nav>
    <aside class="copyright">
      Copyright© 2020 SmartSuperV Technology Co., Ltd. All rights reserved. <br />
   <!-- 国内用 -->
      <!-- <a
        href="http://beian.miit.gov.cn"
        target="_blank"
        class="text"
        >粤ICP备17107183号</a
      ><br />
      <div style="width: 300px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008187"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img
            src="https://ks3-cn-beijing.ksyuncs.com/phantom/html/htmlicon.png"
            style="float: left"
          />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            粤公网安备 44030502008187号
          </p></a
        >
      </div> -->
      <!--Designed by <a href="http://www.wanhu.com.cn" target="_blank">Wanhu</a>.-->
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      share_id: '',
      share_url: 'http://en.magicee.cn/',
      share_title: "Smart Superv",
      share_img: "",
    };
  },
  methods:{
    shareFacebook(){
      function popupwindow(url, title) {
        return window.open(
          "http://www.facebook.com/sharer.php?u=" +
            encodeURIComponent(url),
            //  +
            // +"&t=" +
            // encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      popupwindow(this.share_url, this.share_title);
   
    },
        shareTwitter() {
      function popupwindow(url, title) {
        return window.open(
          "https://twitter.com/intent/tweet?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      popupwindow(this.share_url, this.share_title);
    },

  },
  mounted(){
    this.share_url = window.location.host;
  }
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  overflow: hidden;
  padding: 30px 10px;
  background: #0b1f40;
  // height: 532px;
  box-sizing: border-box;
}
.bottom-menu {
  float: left;
  width: 81.7%;
  dl {
    width: 20%;
    float: left;
    margin-right: 0px;
    text-align: left;
    dd {
      line-height: 36px;
      margin-left: 0px;
      opacity: 0.4;
          overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
      a {
        color: #fff;
      }
    }
    dt {
      font-size: 18px;
      color: #fff;
      padding-bottom: 15px;
    }
  }
}
ol {
  float: right;
  width: 15.29%;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
  }
  dt {
    font-size: 18px;
    color: #fff;
    padding-bottom: 15px;
  }
}
.bdsharebuttonbox {
  margin-top: 20px;
  i {
    font-style: normal;
    color: #bac8e0;
    margin: 0 7px;
    cursor: pointer;
    &:hover{
      color: #374761;
    }
  }
  .iconfont {
    font-size: 40px;
    color: none;
  }
}
.copyright{
      text-align: center;
    color: #fff;
    opacity: 0.3;
    padding-top: 4%;
    a{
          color: #fff;
    text-decoration: underline;
    }
}
@media (max-width:768px) {
 .bottom-menu {
    display: none;
  }
  ol{
    float: none;
    margin: 0 auto;
    width: 100%;
    img{
      width: 50%;
    }
  }
}
</style>>
