<template>
  <div class="header001">
    <div class="app-container">
      <div class="swiper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide
            v-for="item in swiperImg"
            :key="item"
            :style="{ backgroundImage: 'url(' + item + ')' }"
          >
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

        <!-- <div class="skitter-large-box">
                <div class="skitter skitter-large with-thumbs" ref="skitter-large">
                    <ul>
                        <li>
                         <router-link to="#swapBlocks">
                            <img src="https://visualhunt.com/photos/l/1/aurora-borealis-over-mountains-at-night.jpg" class="swapBlocks" />
                        </router-link>
                           
                        </li>
                                                <li>
                            
                                <img src="'../../assets/img/20190820134938391.jpg'" alt="">
                          
                        </li>
                    </ul>
                </div>
            </div> -->
      </div>
    </div>
  </div>
</template>


<script>
//引入swiper样式
import { swiper, swiperSlide } from 'vue-awesome-swiper/dist/vue-awesome-swiper'

import 'swiper/dist/css/swiper.min.css'
 
// Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */) 
export default {
  name: "index",
  components:{
    swiper, swiperSlide
  },
  data() {
    return {
      activeIndex: "1", //导航选中
      show: false,
      minShow: false,
      activc: false,
      menuMode: "horizontal",
      animate: "animate__fadeInDown",
      swiperImg: [
        require("@/assets/img/20190820135023828.jpg"),
        require("@/assets/img/20190820135001875.jpg"),
        require("@/assets/img/20190820134938391.jpg"),
      ],
      swiperOptions: {
        //swiper控制器
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
        // direction : 'vertical',//竖向
        mousewheel: false, //鼠标活动
        loop: true, //轮询
        // initialSlide: 1, //设定初始化时slide的索引
        autoplay: {
        //   //自动播放
          delay: 2000,

        //   stopOnLastSlide: false,

        //   disableOnInteraction: false,
        },
      },
    };
  },
  mounted() {},
  methods: {
        swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
};
</script>

<style lang="less" scoped>
.header001 {
  height: 100%;
  .header002 {
    padding: 1% 5% 0%;
    box-sizing: border-box;
    width: 100%;
    z-index: 9;
    background: #fff;
  }
  .header-left {
    width: 30%;
    display: flex;
    align-items: left;
    .el-menu-demo {
      font-size: 16px;
      position: relative;
      padding-bottom: 26px;
      display: inline-block;
      color: #333333;
      font-weight: 400;
      line-height: 36px;
    }
  }
  .header-right {
    width: 57%;
    margin-left: 13%;
    padding: 7px;
    position: relative;
  }
  .top-meun {
    max-width: 16.666%;
    text-align: center;
  }
  .lang {
    width: 74px;
    border: 1px solid #0068b7;
    line-height: 34px;
    overflow: hidden;
    display: flex;
    margin-bottom: 20px;
    a {
      background: #0068b7;
      color: #fff;
      width: 50%;
      text-align: center;
    }
    .aNoB {
      background: white;
      color: #0068b7;
    }
  }
  .search-right {
    font-size: 30px;
    margin-bottom: 20px;
    .el-icon-search {
      text-align: 30px;
      line-height: 30px;
    }
    .el-icon-search:hover {
      color: #0068b7;
    }
    margin-left: 20px;
    padding: 2px 0;
  }

  //swiper css start
  .app-container {
    .swiper {
      width: 100%;
      height: 500px;
      .swiper-container {
        width: 100%;
        height: 500px;
        .swiper-slide {
          width: 100%;
          height: 100%;
          // padding-bottom: 40%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }

        .swiper-pagination {
          .swiper-pagination-bullet-active {
            background-color: #f29b76;
          }
        }
      }
    }
  }
  //swiper css end
}
</style>
<style lang="less">
.header001 {
  .menu001 {
    padding: 0 10px;
  }

  .swiper-pagination-bullet,
  .info_slide {
    //轮播按钮
    position: relative;
    display: inline-block;
    // width: 116px;
    height: 41px;
    overflow: hidden;
    line-height: 1000px;
    background: url("~@/assets/img/Floor002650_dot.png") no-repeat left center;
    padding: 0 70px 0 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .swiper-pagination-bullet-active,
  .info_slideActive {
    //轮播选择按钮
    position: relative;
    display: inline-block;
    // width: 116px;
    height: 41px;
    overflow: hidden;
    line-height: 1000px;
    background: url("~@/assets/img/Floor002650_dot_on.png") no-repeat left
      center;
    padding: 0 70px 0 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .el-menu-demo {
    .el-submenu__title {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>>
