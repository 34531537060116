<template>
  <div id="app">

    <router-view />


    <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop> -->
  </div>
</template>
<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/footer.vue";
import goTop from "@/components/CommonConponent/goTop.vue";

export default {
  name: "index",
  components: {
    Header,
    Footer,
    goTop,
  },
  data() {
    return {
      //幕布，点击可关闭侧边栏
      active: false,
    };
  },
  methods: {
    changeClick() {
      //点击幕布只能关闭
      this.active = false;
      //触发header中的 clickActive 方法，关闭汉堡列表等
      this.$refs.header.clickActive();
    },
    changeActive() {
      //点击汉堡按钮开启幕布
      this.active = true;
    },
  },
  watch: {
    $route(to, from) {
      //监听路由，当路由改变时，滚动到菜单列表
      // let model = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
      if (to.name == "Home" || from.fullPath == "/") {
        //主页/刷新操作 不做处理
        return true; // 手机端、主页不需要滚动
      } else {
        this.$nextTick(function () {
          //nextTick 解决跳转出错、不跳转问题问题
          //更新幕布，不然后默认初始状态显示出来
          this.active = false
          if(document.querySelector('#topId')){
                  return document
            .querySelector("#topId")
            .scrollIntoView({ behavior: "smooth" });   
          }
        });
      }
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  .body-black {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 68px;
    z-index: 1;
    display: none;
  }
  @media (max-width:992px) {
  .body-black{
    display: block;
  }

}
}
    @media (min-width:1750px) {
      #app{
          div>div,div>nav{
    max-width: 1800px;
    margin: 0 auto;
  }
      }
    }
</style>
